<template>
  <b-container id="how-it-works" class="container-hiw" fluid="">
    <b-row class="side-padding-hiw">
      <b-col class="col-title-hiw">
        <h2>
          {{ $t('basic_how_it_works') }}
        </h2>
        <div style="padding-top: 8px;">{{ $t('main_hiw_text') }}
        </div>
      </b-col>
    </b-row>

    <b-row class="side-padding-hiw row-margin">
      <b-col class="col-hiw" cols="12" sm="4">
        <lottie-animation ref="anim0"
                          v-observe-visibility="{
                            callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 0),
                            once: true,
                            throttle: 500,
                            intersection: {
                              threshold: 0.25,
                            }
                          }"
                          :autoPlay="false"
                          :loop="false"
                          :speed="1"
                          class="anim-icon-hiw"
                          path="animation/icon1.json"
        />
        <h3>{{ $t('main_hiw_data_collection') }}</h3>
        <div class="subtext-hiw text-margin-hiw">{{ $t('main_hiw_data_collection_text') }}
        </div>

      </b-col>
      <b-col class="col-hiw" cols="12" sm="4">
        <lottie-animation ref="anim1"
                          v-observe-visibility="{
                            callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 1),
                            once: true,
                            throttle: 500,
                            intersection: {
                              threshold: 0.25,
                            }
                          }"
                          :autoPlay="false"
                          :loop="false"
                          :speed="1"
                          class="anim-icon-hiw"
                          path="animation/icon2.json"
        />
        <h3>{{ $t('main_hiw_forecast') }}</h3>
        <div class="subtext-hiw text-margin-hiw">{{ $t('main_hiw_forecast_text') }}
        </div>
      </b-col>

      <b-col class="col-hiw" cols="12" sm="4">
        <lottie-animation ref="anim2"
                          v-observe-visibility="{
                            callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 2),
                            once: true,
                            throttle: 500,
                            intersection: {
                              threshold: 0.25,
                            }
                          }"
                          :autoPlay="false"
                          :loop="false"
                          :speed="1"
                          class="anim-icon-hiw"
                          path="animation/icon3.json"
        />
        <h3>{{ $t('main_hiw_crowds') }}</h3>
        <div class="subtext-hiw">{{ $t('main_hiw_crowds_text') }}
        </div>
      </b-col>

    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'ContentHIW',
  methods: {
    visibilityChanged: function (isVisible, entry, index) {
      if (isVisible) {
        if (index === 0 && this.$refs.anim0.anim) {
          this.$refs.anim0.anim.play()
        } else if (index === 1 && this.$refs.anim1.anim) {
          this.$refs.anim1.anim.play()
        } else if (index === 2 && this.$refs.anim2.anim) {
          this.$refs.anim2.anim.play()
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "../assets/css/styles";
/* hiw = 'How it works' part */

.container-hiw {
  padding-top: 56px;
}

.side-padding-hiw {
  padding-left: 0;
  padding-right: 0;
}

.row-margin {
  margin-bottom: 24px;
  margin-top: 24px;
}

.anim-icon-hiw {
  max-width: 200px;
  padding-top: 24px;
}

.col-title-hiw {
  text-align: center;
}

.col-hiw {
  margin-bottom: auto;
}

.subtext-hiw {
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: @sm) {
  /* sm */
  .container-hiw {
    padding-top: 120px;
  }

  .row-margin {
    margin-bottom: 100px;
    margin-top: 60px;
  }

  .anim-icon-hiw {
    max-width: 400px;
  }

  .col-hiw {
    margin-bottom: auto;
  }

  .subtext-hiw {
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media (min-width: @md) {
  /* md */
  .side-padding-hiw {
    padding-left: 32px;
    padding-right: 32px;
  }

  .col-title-hiw {
    text-align: left;
    max-width: 60%;
  }

  .row-margin {
    margin-bottom: 100px;
  }
}

@media (min-width: @lg) {
  /* lg */
  .container-hiw {
    padding-top: 56px;
  }

  .side-padding-hiw {
    padding-left: 120px;
    padding-right: 120px;
  }

  .col-title-hiw {
    max-width: 50%;
  }
}

</style>
