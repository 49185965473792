<template>
  <b-container id="explorer" fluid="">
    <b-row class="text-left root content">
      <b-col class="text-left " lg="7" style="">
        <h2 style="margin-bottom: 12px;">
          {{ $t('main_explorer_title_p1') }}
          <br>
          {{ $t('main_explorer_title_p2') }}</h2>

        <div class="text-container" style="margin-bottom: 48px;">
          {{ $t('main_explorer_text') }}
        </div>

        <a href="https://play.google.com/store/apps/details?id=com.chmelva4.fremenexplorer">
          <img class="store-icon" src="icon/google_play.svg"/>
        </a>
        <a href="https://apps.apple.com/us/app/fremen-explorer/id1562737610">
          <img class="store-icon apple-icon" src="icon/apple_store.svg"/>
        </a>
      </b-col>

      <b-col class="text-right" lg="5">
        <img class="w-100" src="imgs/explorer_app.png" style="max-width: 660px;">
      </b-col>

      <b-col class="text-left" lg="12" style="margin-top: 24px;">
        <h3 style="margin-bottom: 32px; max-width: 400px;">{{ $t('main_explorer_social_title') }}</h3>
        <h5 style="margin-bottom: 12px; font-weight: 500;">{{ $t('main_explorer_social_text') }}</h5>
        <a href="https://www.facebook.com/FreMEncontraCOVID">
          <img alt="facebook" class="icon-link" src="/icon/facebook.svg" style="margin-left: 0">
        </a>
        <a href="https://www.instagram.com/fremenexplorer">
          <img alt="instagram" class="icon-link" src="/icon/ig.svg">
        </a>
        <a href="https://twitter.com/ExplorerFremen">
          <img alt="twitter" class="icon-link" src="/icon/twitter.svg">
        </a>
      </b-col>
    </b-row>

    <b-row>
      <a v-scroll-to="'#how-it-works'"
         class="nav-items"
      >{{ $t('basic_how_it_works') }}
      </a>

      <a v-scroll-to="'#faq'"
         class="nav-items">
        {{ $t('basic_faq') }}
      </a>

      <a v-scroll-to="'#about-us'"
         class="nav-items"
      >{{ $t('basic_about_us') }}
      </a>

      <a class="nav-items" href="/terms-and-privacy-policy"
      >{{ $t('basic_terms_of_use') }}
      </a>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Explorer'
}
</script>

<style lang="less" scoped>
@import "../../assets/css/styles";

.root {
  padding-top: 100px;
}

.nav-items {
  margin-left: 30px;
  margin-bottom: 20px;
}

.text-container {
  width: 100%;
}

.content {
  margin-bottom: 60px;
}

.store-icon {
  transition: .5s;
  height: 64px;
}

.store-icon:hover {
  transform: scale(1.1);
}

.icon-link {
  height: 24px;
  width: 24px;
  margin-left: 12px;
  margin-right: 12px;
}

@media (min-width: @md) {
  /* md */
  .text-container {
    width: 85%;
  }

  .nav-items {
    margin-left: 50px;
  }

}

@media (min-width: @lg) {
  /* lg */
  .root {
    padding-top: 200px;
    padding-left: 8%;
  }

  .text-container {
    width: 60%;
  }

  .content {
    margin-bottom: 200px;
  }

  .apple-icon {
    margin-left: 24px
  }
}

@media (min-width: @xl) {
  /* xl */
  .text-container {
    width: 60%;
  }
}

</style>
