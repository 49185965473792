<template>
  <div id="faq" class="w-100 root">
    <h2 style="margin-bottom: 60px;">{{ $t('main_faq') }}</h2>

    <div class="ml-auto mr-auto text-left faq-container">
      <FAQItem v-for="faqItem in faqItems" v-bind:key="faqItem.id" v-bind:item="faqItem"/>
    </div>
  </div>
</template>

<script>
import FAQItem from '@/components/home/FAQItem'

export default {
  name: 'FAQ',
  components: {
    FAQItem
  },
  data: function () {
    return {
      faqItems: [
        {
          id: 0,
          titleText: this.$t('main_faq_1'),
          collapseText: this.$t('main_faq_1_text')
        },
        {
          id: 1,
          titleText: this.$t('main_faq_2'),
          collapseText: this.$t('main_faq_2_text')
        },
        {
          id: 2,
          titleText: this.$t('main_faq_3'),
          collapseText: this.$t('main_faq_3_text')
        },
        {
          id: 3,
          titleText: this.$t('main_faq_4'),
          collapseText: this.$t('main_faq_4_text')
        },
        {
          id: 4,
          titleText: this.$t('main_faq_5'),
          collapseText: this.$t('main_faq_5_text')
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/styles";

.root {
  padding-top: 60px;
  padding-bottom: 60px;
}

.faq-container {
  width: 85%;
}

@media (min-width: @md) {
  /* md */
  .faq-container {
    width: 75%;
  }

  .root {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (min-width: @lg) {
  /* lg */
  .faq-container {
    width: 60%;
  }

  .root {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

</style>
