<template>
  <b-card class="root" style="border-radius: 10px;">

    <div class="d-inline-flex w-100 align-items-center text-left mb-2">
      <div style="font-weight: 500;">{{ place.name }}</div>
      <img :id="place.osm_id" class="icon-favourite"
           src="../../assets/icon/icon_more_info.svg"
           @click="goToDetail">

      <b-tooltip :target="place.osm_id">
        <div class="favourite-tooltip">
          <div style="font-weight: 500">{{ $t('favourite_tooltip_detail') }}</div>
        </div>
      </b-tooltip>

    </div>
    <div v-if="error" class="text-left">
      <div>
        {{ $t('favourite_error') }}
      </div>
      <button class="reload-btn" @click="fetchDetail(true)">
        {{ $t('error_reload') }}
      </button>
    </div>
    <AreaChart style="max-height: 160px;" v-bind:chart-data="chartData" v-bind:day-index="0"
               v-bind:opening-times="openingTimes"/>

    <vue-element-loading :active="!this.detail && !error" color="@color-blue-dark" spinner="bar-fade-scale"
                         style="border-radius: 10px;"/>
  </b-card>
</template>

<script>
import AreaChart from '@/components/map/AreaChart'
import VueElementLoading from 'vue-element-loading'
import config from '@/config'
import axios from 'axios'

export default {
  name: 'FavouriteItem',
  components: {
    AreaChart,
    VueElementLoading
  },
  props: ['place'],
  mounted () {
    this.fetchDetail(true)
  },
  data: function () {
    return {
      detail: null,
      isFavourite: true,
      error: null
    }
  },
  methods: {
    fetchDetail (requireModel) {
      this.error = null
      this.detail = null

      const url = `${config.baseUrlAPI}/api/detail`
      axios.post(url, {
        place: this.place,
        model: `${requireModel}`
      }).then(response => {
        this.detail = response.data
        if (!this.detail.model) {
          this.error = Error('Model error')
          console.error('[FavouriteItem] Model error')
        }
      }).catch(e => {
        this.error = Error('Network error')
        console.error(`[Network/FavouriteItem] ${e.toString()}`)
      })
    },
    goToDetail () {
      // Form the query the same as in search
      let query = {
        ss: encodeURI(this.place.name),
        st: this.place.category,
        i: this.place.osm_id,
        t: this.place.osm_type,
        lat: this.place.location.lat,
        lon: this.place.location.lon
      }
      this.$router.push({
        name: 'MapPage',
        query: query
      })
    }
  },
  computed: {
    chartData: function () {
      if (this.detail && this.detail.model) {
        return this.detail.model
      } else {
        return null
      }
    },
    /**
     * We need to handle stat when opening times are available, but data is null for single day => an empty array is returned.
     * @returns {null|*[]|*}
     */
    openingTimes: function () {
      if (this.detail && !this.detail.raw_time && this.detail.opening_times) {
        let times = this.detail.opening_times[`${this.detail.model[0].day}`]
        if (times) {
          return times
        } else {
          return []
        }
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/styles";

.root {
  height: 250px;
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.icon-favourite {
  height: 48px;
  width: 48px;
  padding: 10px;
  border-radius: 24px;
  border: solid 1px @color-green;
  margin-left: auto;
  cursor: pointer;
}

.icon-favourite:hover {
  background: @color-green;
}

.favourite-tooltip {
  color: @color-blue-dark !important;
}

.reload-btn {
  background: @color-white;
  color: @color-blue-dark;
  border-radius: 7px;
  border: 2px solid @color-green;
  font-weight: 500;
  margin-top: 12px;
  padding: 8px 16px;
}

.reload-btn:hover {
  background: @color-green;
}

</style>
