var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"container-hiw",attrs:{"id":"how-it-works","fluid":""}},[_c('b-row',{staticClass:"side-padding-hiw"},[_c('b-col',{staticClass:"col-title-hiw"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('basic_how_it_works'))+" ")]),_c('div',{staticStyle:{"padding-top":"8px"}},[_vm._v(_vm._s(_vm.$t('main_hiw_text'))+" ")])])],1),_c('b-row',{staticClass:"side-padding-hiw row-margin"},[_c('b-col',{staticClass:"col-hiw",attrs:{"cols":"12","sm":"4"}},[_c('lottie-animation',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
                          callback: function (isVisible, entry) { return _vm.visibilityChanged(isVisible, entry, 0); },
                          once: true,
                          throttle: 500,
                          intersection: {
                            threshold: 0.25,
                          }
                        }),expression:"{\n                          callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 0),\n                          once: true,\n                          throttle: 500,\n                          intersection: {\n                            threshold: 0.25,\n                          }\n                        }"}],ref:"anim0",staticClass:"anim-icon-hiw",attrs:{"autoPlay":false,"loop":false,"speed":1,"path":"animation/icon1.json"}}),_c('h3',[_vm._v(_vm._s(_vm.$t('main_hiw_data_collection')))]),_c('div',{staticClass:"subtext-hiw text-margin-hiw"},[_vm._v(_vm._s(_vm.$t('main_hiw_data_collection_text'))+" ")])],1),_c('b-col',{staticClass:"col-hiw",attrs:{"cols":"12","sm":"4"}},[_c('lottie-animation',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
                          callback: function (isVisible, entry) { return _vm.visibilityChanged(isVisible, entry, 1); },
                          once: true,
                          throttle: 500,
                          intersection: {
                            threshold: 0.25,
                          }
                        }),expression:"{\n                          callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 1),\n                          once: true,\n                          throttle: 500,\n                          intersection: {\n                            threshold: 0.25,\n                          }\n                        }"}],ref:"anim1",staticClass:"anim-icon-hiw",attrs:{"autoPlay":false,"loop":false,"speed":1,"path":"animation/icon2.json"}}),_c('h3',[_vm._v(_vm._s(_vm.$t('main_hiw_forecast')))]),_c('div',{staticClass:"subtext-hiw text-margin-hiw"},[_vm._v(_vm._s(_vm.$t('main_hiw_forecast_text'))+" ")])],1),_c('b-col',{staticClass:"col-hiw",attrs:{"cols":"12","sm":"4"}},[_c('lottie-animation',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
                          callback: function (isVisible, entry) { return _vm.visibilityChanged(isVisible, entry, 2); },
                          once: true,
                          throttle: 500,
                          intersection: {
                            threshold: 0.25,
                          }
                        }),expression:"{\n                          callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 2),\n                          once: true,\n                          throttle: 500,\n                          intersection: {\n                            threshold: 0.25,\n                          }\n                        }"}],ref:"anim2",staticClass:"anim-icon-hiw",attrs:{"autoPlay":false,"loop":false,"speed":1,"path":"animation/icon3.json"}}),_c('h3',[_vm._v(_vm._s(_vm.$t('main_hiw_crowds')))]),_c('div',{staticClass:"subtext-hiw"},[_vm._v(_vm._s(_vm.$t('main_hiw_crowds_text'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }