export default (category) => {
  if (category === 'supermarket') {
    return '/icon/category_supermarket.svg'
  } else if (category === 'markets') {
    return '/icon/category_supermarket.svg'
  } else if (category === 'shop') {
    return '/icon/category_supermarket.svg'
  } else if (category === 'food') {
    return '/icon/icon_pizza.svg'
  } else if (category === 'restaurant') {
    return '/icon/category_restaurant.svg'
  } else if (category === 'fastfood') {
    return '/icon/category_fastfood.svg'
  } else if (category === 'office') {
    return '/icon/category_office.svg'
  } else if (category === 'park') {
    return '/icon/category_park.svg'
  } else if (category === 'pharmacy') {
    return '/icon/category_pharmacy.svg'
  } else {
    return '/icon/category_other.svg'
  }
}
