<template>
  <div>
    <b-container class="root" fluid="">
      <b-row class="search-background search-h">
        <b-col cols="12">
          <h1 class="title" style="max-lines: 1">{{ $t('main_title_p1') }} </h1>
          <div class="subtext">{{ $t('main_title_sub') }}
          </div>

          <b-row class="ml-auto mr-auto" style="margin-top: 30px; max-width: 920px;">
            <b-col sm="12">
              <autocomplete
                  ref="autocomplete"
                  :aria-label="$t('search_hint')"
                  :debounceTime="200"
                  :get-result-value="getResultValue"
                  :placeholder="$t('search_hint')"
                  :search="search"
                  auto-select
                  @submit="handleSubmit"
                  v-on:onTypeSelected="onTypeSelected">
              </autocomplete>
            </b-col>

          </b-row>

        </b-col>

        <Favourites v-if="$screen.width > 992"/>

      </b-row>
    </b-container>

    <!-- For mobile devices, favourites are moved out of search screen -->
    <b-container v-if="$screen.width <= 992" style="padding-top: 40px;">
      <Favourites/>
    </b-container>
  </div>
</template>

<script>
import Autocomplete from '@/components/SearchBar.vue'
import Favourites from '@/components/home/Favourites'
import axios from 'axios'
import '../assets/css/searchbar.less'
import config from '@/config'

export default {
  name: 'Search',
  components: {
    Autocomplete,
    Favourites
  },
  data: function () {
    return {
      focused: false,
      value: '',
      results: [],
      selectedType: 'markets',
    }
  },
  created () {
    this.error = null
  },
  computed: {
    noResults () {
      return this.value && this.results.length === 0
    }
  },
  methods: {
    search: function (input) {
      const url = `${config.baseUrlAPI}/api/search`

      return new Promise(resolve => {
        if (input.length < 3) {
          return resolve([])
        }
        axios.post(url, {
          search_string: input,
          category: this.selectedType,
          geojson: false
        }).then(response => {
          this.error = null
          const searchString = this.$refs.autocomplete.value
          resolve([{
            osm_id: null,
            name: `${searchString}`
          }, ...response.data.places])
          this.results = response.data.places
        }).catch(e => {
          this.error = e
          resolve([{
            error: e,
            name: this.$t('error_system')
          }])
        })
      })
    },
    getResultValue (result) {
      return result.name
    },
    /***
     * Called when an item from search hint is clicked or enter is pressed on the keyboard when search input is focused.
     * This method results in 3 possible ways:
     * 1) search string and category are pushed as query params to the /map page
     * 2) if user clicks (or presses enter) on some result in search hint list, name and category of the result are
     *    taken and pushed to /map as query params
     * 3) if only 'other' category search results are available, 1st result in the list is selected and pushed as a
     *    query param to the /map page
     */
    handleSubmit (result) {
      
      if (result && this.error) return

      const search_string = this.$refs.autocomplete.value
      let search_result = result
      // Check if only 'other' category is present
      let isOnlyOther = true
      if (this.results) {
        for (let res of this.results) {
          if (res.category !== 'other') {
            isOnlyOther = false
            break
          }
        }
      }

      let query = {
        st: encodeURI(this.selectedType)
      }
      // If only 'other' category is present and result was not clicked on, pick first result and pass it to map
      if ((!search_result || !search_result.osm_id) && isOnlyOther && this.results.length > 0) {
        search_result = this.results[0]
      }

      if (search_result && search_result.category === 'other' && search_result.osm_id) {
        query.ss = `${encodeURI(search_result.name)}`
        query.st = search_result.category
        query.type = this.selectedType
        query.i = search_result.osm_id
        query.t = search_result.osm_type
        query.lat = search_result.location.lat
        query.lon = search_result.location.lon
      } else if (search_result && search_result.location) { // When enter is pressed during result loading, sometimes a result from previous call is used. It would have to be resolved by modifying used library.
        query.ss = `${encodeURI(search_result.name)}`
        // Map is moved to this position after loading
        query.lat = search_result.location.lat
        query.lon = search_result.location.lon
      } else if (this.validSearchString(search_string)) {
        query.ss = `${encodeURI(search_string)}`
      }

      this.$router.push({
        name: 'MapPage',
        query: query
      })
    },
    validSearchString (string) {
      return string && string.length >= 3
    },
    handleFocus () {
      this.focused = true
    },

    handleBlur () {
      this.focused = false
    },

    onTypeSelected (type) {
      this.selectedType = type
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "../assets/css/styles";

.search-background {
  background-image: url('../assets/map.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100%;
  justify-content: center;
}

.title {
  margin-top: 32px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.subtext {
  margin-top: 16px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.root {
  height: calc(100vh - 70px); /* - height of the navbar */
}

.search-h {
  height: calc(100vh - 70px); /* - height of the navbar */
}

@media (min-width: @md) {
  /* md */
  .root {
    height: calc(100vh - 90px); /* - height of the navbar */
  }

  .search-h {
    height: calc(100vh - 90px - 90px); /* - height of the navbar - bottom padding */
  }

  .search-background {
    margin-left: 45px;
    margin-right: 45px;
  }

  .title {
    margin-top: 84px;
  }

  .subtext {
    margin-top: 30px;
    max-width: 100%;
  }
}

@media (min-width: @lg) {
  /* lg */
  .root {
    min-height: 920px;
  }

  .search-h {
    min-height: 760px;
  }
}

@media (min-width: @xl) {
  /* lg */
  .title {
    max-width: 60%;
  }

  .subtext {
    max-width: 50%;
  }
}
</style>
