<script>
import gradstop from 'gradstop'
import { Bar } from 'vue-chartjs'
import chartUtils from '@/utils/chartUtils'

export default {
  extends: Bar,
  props: ['chartData', 'dayIndex', 'openingTimes'],
  data: function () {
    return {
      init: false
    }
  },
  mounted () {
    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      tooltips: {
        backgroundColor: '#FFFFFF',
        titleFontFamily: 'Work Sans',
        titleFontSize: 16,
        titleFontColor: '#0C243D',
        titleAlign: 'center',
        bodyFontFamily: 'Work Sans',
        bodyFontSize: 14,
        bodyFontColor: '#0C243D',
        bodyAlign: 'center',
        borderColor: '#BEDCD4',
        borderWidth: 2,
        displayColors: false,
        callbacks: {
          title: (tooltipItem) => {
            return '' + chartUtils.mins2Time(tooltipItem[0].index * 15) + ' - ' + chartUtils.mins2Time((tooltipItem[0].index + 1) * 15)
          },
          label: (tooltipItem) => {
            const value = tooltipItem.yLabel
            if (value < 1) {
              return this.$t('chart_occupancy_empty')
            } else if (value < 2) {
              return this.$t('chart_occupancy_low')
            } else if (value < 3) {
              return this.$t('chart_occupancy_medium')
            } else if (value < 4) {
              return this.$t('chart_occupancy_high')
            } else {
              return this.$t('chart_occupancy_full')
            }
          }
        }
      },
      scales: {
        xAxes: [{
          display: true,
          gridLines: {
            display: true,
            drawOnChartArea: false,
            drawBorder: true,
          },
          ticks: {
            lineHeight: 1.5,
            fontFamily: 'Work Sans'
          }
        }],
        yAxes: [{
          label: this.$t('chart_relative_occupancy'),
          display: true,
          gridLines: {
            display: true,
            drawOnChartArea: true,
            drawBorder: true,
            borderDash: [1, 3],
            lineWidth: 2
          },
          ticks: {
            display: false,
            min: 0,
            max: 5,
            lineHeight: 1.5,
            fontFamily: 'Work Sans',
            fontSize: 14,
            maxTicksLimit: 6,
            callback: function (/*value, index, values*/) {
              return ''
            }
          }
        }]
      }
    }
    /*const canvas = this.$refs.canvas
    const gradient = canvas.getContext('2d').createLinearGradient(0, 0, 0, 350)
    gradient.addColorStop(0, 'red')
    gradient.addColorStop(.33, 'orange')
    gradient.addColorStop(.66, 'yellow')
    gradient.addColorStop(1, 'green')*/

    /*gradient.addColorStop(0, '#265A92')
    gradient.addColorStop(1, '#B2D6FD')
*/
    if (this.chartData) {
      this.init = true
      this.initialize()
    }
  },
  watch: {
    chartData: function () {
      if (!this.init) {
        this.initialize()
        this.init = true
      }
      this.updateChart()
    },
    dayIndex: function () {
      this.updateChart()
    }
  },
  methods: {
    initialize () {
      const data = this.chartData[this.dayIndex].data
      let labels = []
      let timeNowData = []
      for (let i = 0; i < data.length; i++) {
        labels.push(chartUtils.mins2Time(i * 15))
        timeNowData.push(-50)
      }
      const actualTime = new Date()
      let actualMinute = actualTime.getHours() * 60 + actualTime.getMinutes()
      timeNowData[(actualMinute - (actualMinute % 15)) / 15] = 50
      const chartDataset = {
        labels: labels,
        datasets: [
          {
            label: this.$t('chart_relative_occupancy'),
            //backgroundColor: gradient,
            backgroundColor: this.computeColor(data, this.openingTimes),
            hoverBorderColor: '#BEDCD4',
            hoverBorderWidth: 2,
            fill: true,
            data: this.chartData[this.dayIndex].data,
          }, {
            type: 'line',
            data: timeNowData,
            borderColor: '#BEDCD4',
            borderWidth: 2,
          },
        ]
      }
      this.renderChart(chartDataset, this.options)
    },
    computeColor (data, openingTimes) {
      let gradient = gradstop({
        stops: 50,
        inputFormat: 'hex',
        colorArray: ['#92CB87', '#F9D447', '#EE8043', '#D0674A']
      })
      let colors = []
      let i = 0
      for (let d of data) {
        if ((openingTimes !== null && openingTimes.length > 0)) {
          // When closing time reaches over midnight, it is lower than opening time
          if (openingTimes[1] < openingTimes[0] && ((i * 15) >= openingTimes[1] && (i * 15) < openingTimes[0])) {
            colors.push(undefined)
          } else if (openingTimes[0] < openingTimes[1] && ((i * 15) < openingTimes[0] || (i * 15) >= openingTimes[1])) {
            colors.push(undefined)
          } else {
            let index = Math.floor((d) * 50 / 5)
            colors.push(gradient[index])
          }
        } else {
          let index = Math.floor((d) * 50 / 5)
          colors.push(gradient[index])
        }
        i++
      }
      return colors
    },
    updateChart () {
      if (!this.chartData) return
      const data = this.chartData[this.dayIndex].data
      this.$data._chart.data.datasets[0].data = data
      this.$data._chart.data.datasets[0].backgroundColor = this.computeColor(data, this.openingTimes)
      this.$data._chart.update()
    }
  }
}
</script>

