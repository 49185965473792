<template>
  <b-container fluid="">
    <b-navbar class="navbar-h pt-0 pb-0 side-padding" toggleable="lg">
      <b-navbar-brand class="pt-0 pb-0">
        <b-img
            class="logo"
            src="logo.svg"
            style="cursor: pointer;"
            @click="$router.push('/')"
        ></b-img>
      </b-navbar-brand>

      <b-navbar-toggle
          class="btn-toggle"
          target="nav-collapse"
      ></b-navbar-toggle>

      <b-collapse id="nav-collapse" class="ml-auto mr-auto" is-nav>
        <b-navbar-nav class="ml-auto">
          <a v-if="showScrollPoints"
             v-scroll-to="'#how-it-works'"
             class="padding-middle-items nav-items"
          >{{ $t('basic_how_it_works') }}
          </a>

          <a v-if="showScrollPoints"
             v-scroll-to="'#faq'" class="padding-middle-items nav-items">
            {{ $t('basic_faq') }}
          </a>

          <a v-if="showScrollPoints"
             v-scroll-to="'#about-us'" class="padding-middle-items nav-items"
          >{{ $t('basic_about_us') }}
          </a>

          <a v-if="showScrollPoints" class="padding-middle-items nav-items" href="/info"
          >{{ $t('basic_info') }}
          </a>


          <a class="nav-items" href="https://www.facebook.com/Kdynakoupit.cz">
            <img alt="facebook" class="icon-link" src="/icon/facebook.svg">
          </a>

          <a class="nav-items" href="https://www.instagram.com/kdynakoupit.cz/">
            <img alt="instagram" class="icon-link" src="/icon/ig.svg">
          </a>

          <LanguageSelect/>

        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <!--<b-navbar-nav class="contact-button-container">
          <b-button class="contact-button" style="box-shadow: none;" variant="outline"
          >Kontaktujte nás
          </b-button>
        </b-navbar-nav>-->
      </b-collapse>
    </b-navbar>
  </b-container>
</template>

<script>
import LanguageSelect from '@/components/LanguageSelect'

export default {
  name: 'Navbar',
  props: {
    showScrollPoints: {
      type: Boolean,
      default: true
    }
  },
  components: {
    LanguageSelect
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "../assets/css/styles";

.logo {
  height: 70px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.nav-items {
  margin-top: 10px;
  margin-bottom: 10px;
}

.padding-middle-items {
  padding-left: 0;
  padding-right: 0;
}

.contact-button {
  width: 100%;
  height: 54px;
  border: 2px solid @color-green;
  border-radius: 7px;
}

.contact-button-container {
  padding-left: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.contact-button:hover {
  background: @color-green;
}

.btn-toggle {
  border: none;
}

.btn-toggle:hover,
.btn-toggle:focus {
  background: @color-white;
}

.icon-link {
  height: 20px;
  width: 20px;
}


@media (min-width: @md) {
  /* md */

}

@media (min-width: @lg) {
  /* lg */
  .navbar-h {
    height: 90px;
  }

  .icon-link {
    margin-left: 12px;
    margin-right: 12px;
  }

  .nav-items {
    display: inline-flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-middle-items {
    padding-left: 24px;
    padding-right: 24px;
  }

  .contact-button-container {
    padding-left: 20px;
    margin-left: auto;
  }

  .contact-button {
    width: 174px; /*Same as LOGO width*/
  }
}
</style>
