import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import i18n from '../i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/map',
    name: 'MapPage',
    // route level code-splitting
    // this generates a separate chunk (map.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "map" */ '../views/MapPage.vue')
  },
  {
    path: '/info',
    name: 'InfoPage',
    component: () =>
      import(/* webpackChunkName: "info" */ '../views/InfoPage.vue')
  },
  {
    path: '/instructions',
    name: 'InstructionPage',
    component: () =>
      import(/* webpackChunkName: "instruction" */ '../views/InstructionPage.vue')
  },
  {
    path: '/terms-and-privacy-policy',
    name: 'TermsAndPrivacyPolicyPage',
    component: () =>
      import(/* webpackChunkName: "termsandprivacypolicy" */ '../views/TermsAndPrivacyPolicy.vue')
  }
]

const router = new VueRouter({
  routes: routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  const lang = Vue.$storage.get('language', null)
  if (lang && lang !== i18n.locale) {
    i18n.locale = lang
    next()
  } else if (!lang) {
    const newLang = 'cs'
    Vue.$storage.set('language', newLang)
    i18n.locale = lang
    next()
  } else {
    next()
  }
})

export default router
