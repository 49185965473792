<template>
  <b-row v-if="favourites.length > 0" :cols="favourites.length" align-h="center"
         class="pl-0 pr-0 pl-lg-2 pr-lg-2">
    <b-col v-for="fav in favourites" v-bind:key="fav.osm_id" :lg="12/favourites.length" cols="12">
      <FavouriteItem v-bind:place="fav"/>
    </b-col>
  </b-row>

  <b-row v-else class="pl-0 pr-0 pl-lg-2 pr-lg-2" cols="1">
    <b-col class="p0">
      <b-card class="card-add-favourites">
        <div class="d-inline-flex text-left">
          <img class="img-add-favourite" src="/icon/add_favourite.svg">
          <div>
            <h4 style="margin-bottom: 16px">{{ $t('main_add_favourites_title') }}</h4>
            <div>
              {{ $t('main_add_favourites_text') }}
            </div>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>

</template>

<script>
import FavouriteItem from '@/components/home/FavouriteItem'

export default {
  name: 'Favourites',
  components: {
    FavouriteItem
  },
  data: function () {
    return {
      favourites: []
    }
  },
  mounted () {
    this.favourites = this.$storage.get('favourites', [])
    if (this.favourites.length > 3) {
      this.favourites.length = 3
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/styles";

.card-add-favourites {
  border-radius: 10px;
  margin-top: 20px;
}

.img-add-favourite {
  height: 80px;
  width: 80px;
  margin-left: 10px;
  margin-right: 20px;
}

@media (min-width: @lg) {
  .card-add-favourites {
    height: 180px; /* Image 140px + 2x20px padding */
    max-width: 800px;
    margin-top: 0;
  }

  .img-add-favourite {
    height: 140px;
    width: 140px;
    margin-left: 20px;
    margin-right: 40px;
  }
}

</style>
