<template>
  <b-container id="do-you-know" class="background" fluid="" style="">
    <b-row class="side-padding-custom">
      <b-col class="text-left" sm="7">
        <h2 class="subtext-m" style="font-weight: 500">
          {{ $t('main_info_1_text_p1') }} <span class="text-rect-highlight-blue"
                                                style="font-weight: 600">{{ $t('main_info_1_text_p2') }}</span>
          {{ $t('main_info_1_text_p3') }} <span class="text-rect-highlight-blue"
                                                style="font-weight: 600">{{ $t('main_info_1_text_p4') }}</span>
        </h2>

        <!--<div class="page-btn-lg">
          <b-button class="page-btn" disabled style="margin-right: 30px;">
            <b-icon class="page-btn-icon" icon="arrow-left"></b-icon>
          </b-button>

          <b-button class="page-btn">
            <b-icon class="page-btn-icon" icon="arrow-right"></b-icon>
          </b-button>
        </div>-->
      </b-col>

      <b-col class="mt-auto mb-auto " sm="5">
        <b-img-lazy class="img-info" fluid src="imgs/info_img_1.png"></b-img-lazy>
        <!-- Copy from above buttons, only shown on mobile -->
        <!--    <div class="page-btn-sm text-right">
              <b-button class="page-btn" disabled style="margin-right: 30px">
                <b-icon class="page-btn-icon" icon="arrow-left"></b-icon>
              </b-button>

              <b-button class="page-btn">
                <b-icon class="page-btn-icon" icon="arrow-right"></b-icon>
              </b-button>
            </div>-->
      </b-col>

    </b-row>

  </b-container>
</template>

<script>
export default {
  name: 'ContentInfo'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "../assets/css/styles";

.background {
  background: @color-peach;
  margin-top: 60px;
}

.page-btn {
  width: 60px;
  height: 60px;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  background: @color-white;
  border: none;
}

.page-btn-icon {
  color: @color-blue-dark;
}

.page-btn:disabled {
  background: @color-white;
  opacity: 0.5;
}

.page-btn:hover {
  background: @color-white;
}

.page-btn:focus {
  background: @color-white;
  box-shadow: none;
}

.page-btn:active {
  background: @color-blue-dark;
  box-shadow: none;
}

.page-btn-sm {
  padding-right: 30px;
}

.page-btn-lg {
  display: none;
}

.side-padding-custom {
  padding-left: 0;
  padding-right: 0;
  padding-top: 60px;
  padding-bottom: 110px;
}

.subtext-m {
  margin-top: 32px;
}

.img-info {
  max-height: 533px;
}

@media (min-width: @sm) {
  /* sm */
  .page-btn-sm {
    display: none;
  }

  .page-btn-lg {
    display: inherit;
  }
}

@media (min-width: @md) {
  /* md */
}

@media (min-width: @lg) {
  /* lg */
  .side-padding-custom {
    padding-left: 80px;
  }

  .subtext-m {
    width: 90%;
  }
}

@media (min-width: @xl) {
  /* xl */
  .side-padding-custom {
    padding-left: 180px;
    padding-top: 130px;
  }

  .background {
    margin-top: 170px;
  }

  .subtext-m {
    width: 75%;
  }
}

</style>
