<template>
  <div class="locale-changer">
    <b-select v-model="$i18n.locale" class="locale-select" @change="langChange">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
        {{ $t(`${lang}`) }}
      </option>
    </b-select>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelect',
  data () {
    return {
      langs: ['cs', 'en']
    }
  },
  mounted () {
    const lang = this.$storage.get('language', null)
    if (lang) {
      this.$i18n.locale = lang
    }
  },
  methods: {
    langChange (lang) {
      this.$storage.set('language', lang)
      this.$router.go(0) // refresh the page so strings in non-reactive elements are loaded again with the right language
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/styles";

.locale-select {
  // background-image: url("/icon/icon_arrow_down.svg");
  background-color: @color-white;
  outline: none;
  border: none;
}
</style>
