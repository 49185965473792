import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueScreen from 'vue-screen'
import { Vue2Storage } from 'vue2-storage'

Vue.use(Vue2Storage)
Vue.use(VueScreen, 'bootstrap')
const VueScrollTo = require('vue-scrollto');
import LottieAnimation from 'lottie-vuejs' // import lottie-vuejs
import VueObserveVisibility from 'vue-observe-visibility'
import VueGeolocation from 'vue-browser-geolocation'

Vue.use(VueGeolocation)
Vue.use(VueObserveVisibility)
Vue.use(LottieAnimation) // add lottie-animation to your global scope
Vue.config.productionTip = false;

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/main.less'
import './assets/css/mapbox-gl.css'
import i18n from './i18n'

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
