<template>
  <div ref="root">
    <slot
        :inputListeners="inputListeners"
        :inputProps="inputProps"
        :resultListListeners="resultListListeners"
        :resultListProps="resultListProps"
        :resultProps="resultProps"
        :results="results"
        :rootProps="rootProps"
        :rowProps="rowProps"
    >
      <div v-bind="rootProps">
        <b-row v-bind="rowProps">
          <b-col class="h-100 autocomplete-tb-p d-inline-flex align-items-center" lg="6" order="0" order-lg="0"
                 style="padding-left: 8px; padding-right: 0;">
            <input
                ref="input"
                v-bind="inputProps"
                v-on="$listeners"
                @blur="core.handleBlur"
                @focus="inputFocus"
                @input="handleInput"
                @keydown="core.handleKeyDown"
            />
            <!--<img class="h2 mt-auto mb-auto btn-location" src="/icon/location_off.svg" style="cursor: pointer;"/>-->
            <div class="autocomplete-v-divider"></div>
          </b-col>

          <b-col class="pl-0 pr-0 pr-lg-2 autocomplete-tb-p h-100 type-select" lg="3" order="2" order-lg="1">
            <b-form-select v-model="selectedType" :options="typeOptions" class="h-100 w-100 autocomplete-type-btn"
                           style="box-shadow: none; border: none;"
                           @change="$emit('onTypeSelected', selectedType)">
            </b-form-select>
          </b-col>


          <b-col class="pl-0 autocomplete-tb-p h-100 pr-0 pr-lg-2" lg="3" order="3" order-lg="1">
            <button class="w-100 h-100 p-0 btn-search" @click="$emit('submit')">
              {{ $t('basic_search') }}
            </button>
          </b-col>

          <b-col class="pl-0" order="1" order-lg="4">
            <ul
                ref="resultList"
                v-bind="resultListProps"
                v-on="resultListListeners"
            >
              <template v-for="(result, index) in results">
                <slot :props="resultProps[index]" :result="result" name="result">
                  <li :key="resultProps[index].id" v-bind="resultProps[index]">
                    <img :src="category2icon(result.category)"
                         style="width: 24px; height: 24px; opacity: 0.25; margin-right: 12px;">
                    {{ getResultValue(result) }}
                  </li>
                </slot>
              </template>
            </ul>
          </b-col>

        </b-row>
      </div>
    </slot>
  </div>
</template>

<script>
import AutocompleteCore from '../utils/searchbar/AutocompleteCore.js'
import uniqueId from '../utils/searchbar/uniqueId.js'
import getRelativePosition from '../utils/searchbar/getRelativePosition.js'
import debounce from '../utils/searchbar/debounce.js'
import category2icon from '@/utils/category2icon'

export default {
  name: 'Autocomplete',
  inheritAttrs: false,

  props: {
    search: {
      type: Function,
      required: true,
    },
    baseClass: {
      type: String,
      default: 'autocomplete',
    },
    autoSelect: {
      type: Boolean,
      default: false,
    },
    getResultValue: {
      type: Function,
      default: result => result,
    },
    defaultValue: {
      type: String,
      default: '',
    },
    debounceTime: {
      type: Number,
      default: 0,
    },
  },

  data () {
    const core = new AutocompleteCore({
      search: this.search,
      autoSelect: this.autoSelect,
      setValue: this.setValue,
      onUpdate: this.handleUpdate,
      onSubmit: this.handleSubmit,
      onShow: this.handleShow,
      onHide: this.handleHide,
      onLoading: this.handleLoading,
      onLoaded: this.handleLoaded,
    })
    if (this.debounceTime > 0) {
      core.handleInput = debounce(core.handleInput, this.debounceTime)
    }

    return {
      core,
      value: this.defaultValue,
      resultListId: uniqueId(`${this.baseClass}-result-list-`),
      results: [],
      selectedIndex: -1,
      expanded: false,
      loading: false,
      position: 'below',
      resetPosition: true,
      /* Type of object */
      typeOptions: [
        {
          value: 'markets',
          text: this.$t('category_markets')
        },
        {
          value: 'food',
          text: this.$t('category_food')
        },
        {
          value: 'pharmacy',
          text: this.$t('category_pharmacy')
        },
        {
          value: 'park',
          text: this.$t('category_park')
        },
        {
          value: 'office',
          text: this.$t('category_office')
        }/*,
        {
          value: 'all',
          text: this.$t('category_all')
        }*/
      ],
      selectedType: 'markets'
    }
  },

  computed: {
    typeSelectProps () {
      return {
        value: this.selectedType
      }
    },
    rootProps () {
      return {
        class: this.baseClass,
        style: { position: 'relative' },
        'data-expanded': this.expanded,
        'data-loading': this.loading,
        'data-position': this.position,
      }
    },
    rowProps () {
      return {
        class: `${this.baseClass}-row`,
        'aria-expanded': this.expanded ? 'true' : 'false',
      }
    },
    inputProps () {
      return {
        class: `${this.baseClass}-input`,
        value: this.value,
        role: 'combobox',
        autocomplete: 'off',
        autocapitalize: 'off',
        autocorrect: 'off',
        spellcheck: 'false',
        'aria-autocomplete': 'list',
        'aria-haspopup': 'listbox',
        'aria-owns': this.resultListId,
        'aria-expanded': this.expanded ? 'true' : 'false',
        'aria-activedescendant':
            this.selectedIndex > -1
                ? this.resultProps[this.selectedIndex].id
                : '',
        ...this.$attrs,
      }
    },
    inputListeners () {
      return {
        input: this.handleInput,
        keydown: this.core.handleKeyDown,
        focus: this.core.handleFocus,
        blur: this.core.handleBlur,
      }
    },
    resultListProps () {
      const yPosition = this.position === 'below' ? 'top' : 'bottom'
      return {
        id: this.resultListId,
        class: `${this.baseClass}-result-list`,
        role: 'listbox',
        style: {
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          visibility: this.expanded ? 'visible' : 'hidden',
          pointerEvents: this.expanded ? 'auto' : 'none',
          [yPosition]: '100%',
        },
      }
    },
    resultListListeners () {
      return {
        mousedown: this.core.handleResultMouseDown,
        click: this.core.handleResultClick,
      }
    },
    resultProps () {
      return this.results.map((result, index) => ({
        id: `${this.baseClass}-result-${index}`,
        class: `${this.baseClass}-result`,
        'data-result-index': index,
        role: 'option',
        ...(this.selectedIndex === index ? { 'aria-selected': 'true' } : {}),
      }))
    },
  },

  mounted () {
    document.body.addEventListener('click', this.handleDocumentClick)
  },

  beforeDestroy () {
    document.body.removeEventListener('click', this.handleDocumentClick)
  },

  updated () {
    if (!this.$refs.input || !this.$refs.resultList) {
      return
    }
    if (this.resetPosition && this.results.length > 0) {
      this.resetPosition = false
      this.position = getRelativePosition(
          this.$refs.input,
          this.$refs.resultList
      )
    }
    this.core.checkSelectedResultVisible(this.$refs.resultList)
  },

  methods: {
    category2icon (category) {
      return category2icon(category)
    },

    inputFocus (event) {
      this.core.handleFocus(event)
    },

    setValue (result) {
      this.value = result ? this.getResultValue(result) : ''
    },

    handleUpdate (results, selectedIndex) {
      this.results = results
      this.selectedIndex = selectedIndex
      this.$emit('update', results, selectedIndex)
    },

    handleShow () {
      this.expanded = true
    },

    handleHide () {
      this.expanded = false
      this.resetPosition = true
    },

    handleLoading () {
      this.loading = true
    },

    handleLoaded () {
      this.loading = false
    },

    handleInput (event) {
      this.value = event.target.value
      this.core.handleInput(event)
    },

    handleSubmit (selectedResult) {
      this.$emit('submit', selectedResult)
    },

    handleDocumentClick (event) {
      if (this.$refs.root.contains(event.target)) {
        return
      }
      this.core.hideResults()
    },
  },
}
</script>
