<template>
  <div class="body">
    <Navbar/>
    <Search/>
    <ContentHIW/>
    <ContentInfo/>
    <FAQ/>
    <AboutUs/>
    <Explorer/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Search from '@/components/Search.vue'
import ContentHIW from '@/components/ContentHIW.vue'
import ContentInfo from '@/components/ContentInfo.vue'
import FAQ from '@/components/home/FAQ'
import AboutUs from '@/components/home/AboutUs'
import Explorer from '@/components/home/Explorer'

export default {
  name: 'HomePage',
  components: {
    Navbar,
    Search,
    ContentHIW,
    ContentInfo,
    FAQ,
    AboutUs,
    Explorer
  }
    }
</script>
