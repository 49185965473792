<template>
  <div id="about-us" class="root">
    <h2 style="margin-bottom: 28px;">{{ $t('main_about_us_title') }}</h2>

    <div class="ml-auto mr-auto container" style="margin-bottom: 56px;">
      <div v-html="$t('main_about_us_text')"/>
    </div>
    <b-img-lazy src="/icon/cvut.svg"/>
  </div>
</template>

<script>
export default {
  name: 'AboutUs'
}
</script>

<style lang="less" scoped>
@import "../../assets/css/styles";

.container {
  width: 85%;
}

.root {
  background: @color-green;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (min-width: @md) {
  /* md */
  .container {
    width: 75%;
  }
}

@media (min-width: @lg) {
  /* lg */
  .container {
    width: 50%;
  }
}

</style>
