<template>
  <div class="w-100">
    <div v-b-toggle="`collapse-${item.id}`" class="w-100 d-inline-flex align-items-center"
         style="margin-bottom: 28px; margin-top: 36px;">

      <h4 style="margin-bottom: 0; margin-right: auto;">{{ item.titleText }}</h4>
      <img :src="shown ? '/icon/minus.svg' : '/icon/plus.svg'" style="width: 24px; height: 24px;">
    </div>

    <b-collapse v-model="shown" v-bind="collapseProps" class="text-left collapse-text" v-html="item.collapseText">

    </b-collapse>

    <div class="divider"/>
  </div>
</template>

<script>
export default {
  name: 'FAQItem',
  props: ['item'],
  data: function () {
    return {
      shown: false
    }
  },
  computed: {
    collapseProps: function () {
      return {
        id: `collapse-${this.item.id}`
      }
    },
    toggleBtnProps: function () {
      return {}
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/styles";

.collapse-text {
  width: 100%;
}

.divider {
  border: 1px solid @color-grey;
  margin-top: 12px;
}

@media (min-width: @md) {
  /* md */
  .collapse-text {
    width: 85%;
  }
}

@media (min-width: @lg) {
  /* lg */
  .collapse-text {
    width: 75%;
  }
}

</style>
